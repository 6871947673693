import { useEffect, useState } from 'react'
import { Loader } from 'react-loaders'
import ToniPic from '../../assets/images/Toni_without_bg.png'
import {Link} from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import './index.scss'


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['T','o','n','i']
    const jobArray = ['w','e','b',' ','d','e','v','e','l','o','p','e','r']

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)
    
        return () => clearTimeout(timeoutId) // Clear the timeout on component unmount
    }, [])

    return (
        <div>
            <div className="container home-page">
                <div className='text-zone'>
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i</span>
                        <span className={`${letterClass} _13`}>,</span><img src={ToniPic} alt="developer" />
                        <br />
                        <span className={`${letterClass} _14`}>I</span>
                        <span className={`${letterClass} _15`}>'</span>
                        <span className={`${letterClass} _16`}>m</span>
                        <span className={`${letterClass} _17`}>,</span>
                    
                        
                    
                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                        
                        <br />
                    
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22}/>
                    </h1>
                    
                    <h2>Frontend & Backend Developer / Java Spring Boot und Next.js Expert</h2>
                    
                    <Link to="/contact" className="flat-button">CONTACT ME</Link>
                </div>
                <Logo />
            </div>
            <Loader type="pacman" />
        </div>
        
    )
}

export default Home