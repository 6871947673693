import './index.scss'
import LogoRune from '../../../assets/images/Toni.png'
import { useEffect, useRef } from 'react'

const Logo = () => {

    const bgRef = useRef()
    const outlineLogoRuneRef = useRef()
    const solidLogoRuneRef = useRef()

    

    return (
        <div className='logo-container' ref={bgRef}>
            <img ref={solidLogoRuneRef} className='solid-logo' src={ LogoRune } alt='Logo Rune' />
            <svg 
                width="278" 
                height="307" viewBox="0 0 278 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g 
                    className='svg-container' 
                    filter="url(#filter0_d_3_16)">
                <path ref={outlineLogoRuneRef} d="M137.95 293.065C207.013 293.065 263 237.078 263 168.015C263 98.9519 207.013 42.9652 137.95 42.9652C68.8872 42.9652 12.9005 98.9519 12.9005 168.015C12.9005 237.078 68.8872 293.065 137.95 293.065Z" stroke="white" stroke-width="10"/>
                <line x1="9" y1="47.9652" x2="9.00001" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="9" y1="47.9652" x2="9.00001" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="9" y1="47.9652" x2="9.00001" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="9" y1="47.9652" x2="9.00001" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="9" y1="47.9652" x2="9.00001" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="17.9005" y1="42.9652" x2="258" y2="42.9652" stroke="white" stroke-width="10"/>
                <line x1="17.9005" y1="42.9652" x2="258" y2="42.9652" stroke="white" stroke-width="10"/>
                <line x1="17.9005" y1="42.9652" x2="258" y2="42.9652" stroke="white" stroke-width="10"/>
                <line x1="17.9005" y1="42.9652" x2="258" y2="42.9652" stroke="white" stroke-width="10"/>
                <line x1="17.9005" y1="42.9652" x2="258" y2="42.9652" stroke="white" stroke-width="10"/>
                <line x1="140.423" y1="47.9652" x2="140.423" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="140.423" y1="47.9652" x2="140.423" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="140.423" y1="47.9652" x2="140.423" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="140.423" y1="47.9652" x2="140.423" y2="288.065" stroke="white" stroke-width="10"/>
                <line x1="140.423" y1="47.9652" x2="140.423" y2="288.065" stroke="white" stroke-width="10"/>
                <ellipse cx="141.741" cy="16.3731" rx="11.3731" ry="11.3731" fill="white"/>
                </g>
                <g filter="url(#filter1_d_3_16)">
                <path d="M143.95 288.065C213.013 288.065 269 232.078 269 163.015C269 93.9519 213.013 37.9652 143.95 37.9652C74.8872 37.9652 18.9005 93.9519 18.9005 163.015C18.9005 232.078 74.8872 288.065 143.95 288.065Z" stroke="#022C43" stroke-width="10"/>
                <line x1="15" y1="42.9652" x2="15" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="15" y1="42.9652" x2="15" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="15" y1="42.9652" x2="15" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="15" y1="42.9652" x2="15" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="15" y1="42.9652" x2="15" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="23.9005" y1="37.9652" x2="264" y2="37.9652" stroke="#022C43" stroke-width="10"/>
                <line x1="23.9005" y1="37.9652" x2="264" y2="37.9652" stroke="#022C43" stroke-width="10"/>
                <line x1="23.9005" y1="37.9652" x2="264" y2="37.9652" stroke="#022C43" stroke-width="10"/>
                <line x1="23.9005" y1="37.9652" x2="264" y2="37.9652" stroke="#022C43" stroke-width="10"/>
                <line x1="23.9005" y1="37.9652" x2="264" y2="37.9652" stroke="#022C43" stroke-width="10"/>
                <line x1="146.423" y1="42.9652" x2="146.423" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="146.423" y1="42.9652" x2="146.423" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="146.423" y1="42.9652" x2="146.423" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="146.423" y1="42.9652" x2="146.423" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <line x1="146.423" y1="42.9652" x2="146.423" y2="283.065" stroke="#022C43" stroke-width="10"/>
                <ellipse cx="147.741" cy="11.3731" rx="11.3731" ry="11.3731" fill="#022C43"/>
                </g>
                <defs>
                <filter id="filter0_d_3_16" x="0" y="5" width="272" height="301.065" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_16"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_16" result="shape"/>
                </filter>
                <filter id="filter1_d_3_16" x="6" y="0" width="272" height="301.065" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_16"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_16" result="shape"/>
                </filter>
                </defs>
                </svg>
        </div>
    )
}

export default Logo