import { Loader } from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect, useRef } from 'react'
import './index.scss'
import emailjs from '@emailjs/browser'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const Contact = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    
        return () => clearTimeout(timeoutId) // Clear the timeout on component unmount
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()
        // sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
        emailjs
            .sendForm(
                'service_kqohc9m',
                'template_yczt18l',
                refForm.current,
                'enTdHg4HdCuKiacED'
            )
            .then (
                () => {
                    alert('Message successfully sent!')
                    window.location.reload(true)
                },
                () => {
                    alert('Failed to send the message, please try again.')
                }
            )
    }

    return (
        <div>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters 
                        letterClass={ letterClass }
                        strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} 
                        idx={15} />
                    </h1>
                    <p>I am interested in freelance opportunities, especially ambitious or large projects. However, if you have other requests or questions, please don't hesitate to contact me using the form below.
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' name='name' placeholder='Name' required />
                                </li>
                                <li className='half'>
                                    <input type='email' name='email' placeholder='Email' required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder='Message' name='message' required></textarea>
                                </li>
                                <li>
                                    <input type='submit' className='flat-button' value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className="info-map">
                    Toni Zeidler,
                    <br />
                    Germany,
                    <br />
                    Konrad-Adenauer-Ufer 79-81,
                    <br />
                    50668 Cologne,
                    <br />
                    <span>toni.zeidler@gmx.de</span>
                </div>
                <div className='map-wrap'>
                    <MapContainer center={[50.950779, 6.9666559]} zoom={15}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[50.950779, 6.9666559]}>
                            <Popup>Tony resides here; join for a cup of coffee and some delightful company! 😊</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            
            <Loader type="pacman" />
        </div>
        
    )
}

export default Contact