import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngular, faHtml5, faCss3, faReact, faGitAlt, faJs } from '@fortawesome/free-brands-svg-icons'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { useState, useEffect } from 'react'
import { Loader } from 'react-loaders'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    
        return () => clearTimeout(timeoutId) // Clear the timeout on component unmount
    }, [])

    return (
        <div>

            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I'm an ambitious web developer proficient in both frontend and backend technologies. As an expert in Java Spring Boot and Next.js, I am actively seeking a role in an established IT company that offers the opportunity to work with the latest technologies on challenging and diverse projects.</p>

                    <p>
                        I am quietly confident, naturally curious, and consistently working on enhancing my skills, tackling design problems one step at a time.</p>

                    <p>
                        If I need to define myself in one sentence, that would be a passionate and dedicated web developer with expertise in Java Spring Boot and Next.js, constantly pursuing growth and excellence in crafting innovative solutions.
                    </p>
                </div>
                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faHtml5} color='#f06529' />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faCss3} color='#28a4d9' />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faReact} color='#5ed4f4' />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faAngular} color='#dd0031' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faJs} color='#efd81d' />
                        </div>
                        <div className='face6'>
                            <FontAwesomeIcon icon={faGitAlt} color='#ec4d28' />
                        </div>
                    </div>
                </div>
            </div>

            <Loader type="pacman"  />
        </div>
    )
}

export default About